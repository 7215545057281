<h2 class="accordion-header" [id]="'headingPart-' + examPart.path">
  <button class="accordion-button" type="button" data-bs-toggle="collapse"
          [attr.data-bs-target]="'#collapsePart' + examPart.path" aria-expanded="true" [attr.aria-controls]="'collapsePart' + examPart.path">
    {{ examPart.partName }} - {{ examPart.score || 0 | percent }}
  </button>
</h2>
<div [id]="'collapsePart' + examPart.path" class="accordion-collapse collapse" [attr.aria-labelledby]="'headingPart-' + examPart.path">
  <div class="accordion-body p-1 p-sm-3">
    <ng-template loadExamPartResult></ng-template>
  </div>
</div>
